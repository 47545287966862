.wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.content-header {
  width: 100%;
  background-color: #fff;
  border-bottom: 1px solid #ddd;
  height: 80px;
}

.content-body {
  height: calc(100vh - 136px);
  display: flex;

  .section-sidebar {
    width: 250px;
    height: 100%;
    overflow-y: auto;
    z-index: 1;
    flex-shrink: 0;
    background-color: #fff;

    .sidebar-accordion {
      border-bottom: 1px solid #ddd;
    }
  }

  .section-content {
    width: 100%;
    height: 100%;
    overflow: hidden auto;

    .card-header {
      padding: 0 !important;
      background-color: #fff;
      .nav-underline {
        gap: 0 !important;
        .nav-item {
          .nav-link {
            padding: 5px 20px;
            color: #000;
            &.active {
              font-weight: normal !important;
              background-color: var(--op-primary-hover-color);
              border-bottom: 2px solid var(--op-primary-color);
            }
            &:hover {
              font-weight: normal !important;
              background-color: #f5f5f6;
              border-bottom: 2px solid var(--op-primary-color);
            }
          }
        }
      }
    }
  }
}

.activity-tab {
  .btn {
    padding: 5px 15px !important;
    border: var(--bs-border-width) solid var(--bs-border-color);

    &.active {
      border: 2px solid var(--op-primary-color) !important;
      background-color: var(--op-primary-hover-color) !important;
    }

    &:hover {
      background-color: #f5f5f6;
      border-bottom: 2px solid #f5f5f6;
    }
  }
}

.activity-filter {
  .nav-item {
    .nav-link {
      color: #000;
      &.active {
        background-color: var(--op-primary-color) !important;
        color: #fff;
      }

      &:hover {
        background-color: var(--op-primary-hover-color);
      }

      .badge {
        --bs-badge-padding-x: 5px;
        --bs-badge-padding-y: 3px;
        --bs-badge-font-size: 10px;
        --bs-badge-font-weight: normal;
        --bs-badge-color: #fff;
        --bs-badge-border-radius: 4px;
      }
    }
  }
}
