.page-wrapper {
  display: flex;
  width: 100%;
  height: calc(100vh - 56px);

  .page-start {
    width: 25%;
    height: 100%;

    .header {
      height: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-bottom: 1px solid #ddd;
      box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
      padding: 10px 20px;
    }

    .content {
      height: calc(100vh - 156px);
      width: 100%;
      overflow-y: auto;
      overflow-x: hidden;

      &::-webkit-scrollbar {
        width: 0.3rem;
      }

      .item {
        position: relative;
        background: #f3f3f3;
        transition: 0.3s ease;
        width: 100%;
        padding: 2px 0px;

        &.active {
          background: #e5e6e8;
        }

        &::before {
          content: "";
          position: absolute;
          width: 80%;
          bottom: 0;
          left: 80px;
          border-bottom: 1px solid #e5e6e8;
        }

        &:hover {
          background: #e5e6e8;
          cursor: pointer;
        }

        .item-start {
          position: relative;
          padding: 10px 20px 10px 20px;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          height: 80%;
          width: 100%;

          .initial-wrapper {
            min-width: 35px;
            height: 35px;
            border-radius: 40px;
            background: var(--op-primary-color);
            display: flex;
            justify-content: center;
            align-items: center;

            .initial {
              font-size: 13px;
              font-family: poppins, "sans-serif" !important;
              color: #fff;
              text-align: center;
              flex: 1;
            }
          }

          .content-item {
            margin-left: 12px;
            flex: 10;

            h6 {
              margin-bottom: 0;
              color: #000;
              font-size: 13px;
            }

            p {
              margin: 0;
              font-size: 12px;
              color: #999;
            }
          }

          .content-time {
            p {
              color: #999;
              font-size: 12px;
              white-space: nowrap;
              margin-top: -16px;
            }

            .new-message {
              box-sizing: border-box;
              background-color: #5cb85c;
              width: 10px;
              height: 10px;
              border-radius: 10px;
              display: inline-block;
              position: absolute;
              right: 20px;
              top: 50%;
              font-size: 0.9rem;
              color: #fff;
              text-align: center;
            }

            .new-attention {
              box-sizing: border-box;
              background-color: #e82960;
              width: 10px;
              height: 10px;
              border-radius: 10px;
              display: inline-block;
              position: absolute;
              right: 35px;
              top: 50%;
              font-size: 0.9rem;
              color: #fff;
              text-align: center;
            }
          }
        }

        .item-footer {
          position: absolute;
          bottom: 2px;
          right: 20px;
          font-size: 8.5px;
          text-align: right;
        }
      }
    }
  }

  .page-content {
    width: 55%;
    height: 100%;
    background-color: #fff;
    border-right: 1px solid #ddd;
    border-left: 1px solid #ddd;

    .content {
      width: 100%;
      height: 90%;
      overflow-y: scroll;
      overflow-x: hidden;
      display: flex;
      flex-direction: column-reverse;

      &::-webkit-scrollbar {
        width: 0.3rem;
      }

      .chat-wrapper {
        display: flex;
        flex-direction: column;

        .new {
          transform: scale(0);
          transform-origin: 0 0;
          animation: bounce 500ms linear both;
        }

        .chat-timestamp {
          line-height: 0.5;
          text-align: center;
          color: #a9a9a9;
          position: sticky;
          position: -webkit-sticky;
          top: 0;
          z-index: 2;

          span {
            display: inline-block;
            position: relative;
            border: none;
            border-radius: 40px;
            background: #e5e6e8;
            color: #000;
            padding: 10px 20px;
            margin: 10px;
          }
        }

        .chat {
          display: flex;
          flex-direction: column;

          .inbound-wrapper {
            display: flex;
            align-self: flex-start;
            margin-left: 20px;
            max-width: 70%;

            .inbound {
              border-radius: 0.5rem;
              padding: 0.5rem;
              position: relative;
              word-wrap: break-word;
              line-height: 80%;
              margin-bottom: 5px;
              // margin-top: 10px;
              z-index: 1;
              background: #e5e6e8;
              max-width: 100%;
              color: #000;

              &.firstchild {
                margin-bottom: 5px;
                margin-top: 10px;
              }

              &.firstchild::before {
                content: "";
                position: absolute;
                width: 0;
                height: 0;
                top: 0px;
                left: -10px;
                bottom: auto;
                border: 22px solid;
                border-color: #e5e6e8 transparent transparent transparent;
                z-index: -1;
              }

              .file {
                width: 500px;
                height: 50px;
                border: 1px solid #eee;
                border-radius: 3px;
                display: flex;
                align-items: center;
                box-shadow: rgba(220, 242, 255, 0.2) 0px 8px 24px;
                background: #f3f3f3;
                text-decoration: none;
                color: #000 !important;
              }

              .inbound-waiting {
                display: flex;
                align-items: center;
              }

              .inbound-extra {
                float: right;
                padding-left: 3rem;
                margin: 0;
                color: #000;
                font-size: 10px;
              }
            }

            .inbound-refresh {
              display: flex;
              align-items: center;
              padding: 0 10px;
              cursor: pointer;
            }
          }

          .outbound-wrapper {
            display: flex;
            align-self: flex-end;
            margin-right: 20px;
            max-width: 70%;

            .outbound-error {
              display: flex;
              align-items: center;
              padding: 0 10px;
              color: #d9534f;
              font-size: 17px;
              cursor: pointer;
            }

            .outbound {
              border-radius: 0.5rem;
              padding: 0.5rem;
              position: relative;
              word-wrap: break-word;
              line-height: 80%;
              margin-bottom: 5px;
              // margin-top: 10px;
              z-index: 1;
              background: #0d6efd;
              max-width: 100%;
              color: #fff;
              

              a {
                color: #fff !important;
              }

              &.firstchild {
                margin-bottom: 5px;
                margin-top: 10px;
              }

              &.firstchild::before {
                content: "";
                position: absolute;
                width: 0;
                height: 0;
                right: -10px;
                top: 0px;
                bottom: auto;
                border: 32px solid;
                border-color: #0d6efd transparent transparent transparent;
                z-index: -1;
              }

              .file {
                width: 500px;
                height: 50px;
                border: 1px solid #fff;
                border-radius: 3px;
                display: flex;
                align-items: center;
                box-shadow: rgba(220, 242, 255, 0.2) 0px 8px 24px;
                background: #fff;
                color: #000 !important;
                text-decoration: none;
                padding: 0 15px;
              }

              .outbound-extra {
                float: right;
                padding-left: 3rem;
                margin: 0;
                color: #f4f4f4;
                font-size: 10px;
              }
            }
          }
        }
      }
    }

    .footer {
      width: 100%;
      height: 10%;
      display: flex;
      align-items: center;
      background: #f3f3f3;
      padding: 0px 10px;

      .input-tools {
        width: 80px;
        display: flex;
        justify-content: center;
      }

      .input {
        background-color: transparent !important;
        border: none !important;

        &:focus {
          border: none !important;
          box-shadow: none !important;
        }
      }

      button:disabled {
        opacity: 0.2;
      }
    }
  }

  .page-end {
    width: 20%;
    height: 100%;

    .content {
      height: 100%;

      .content-user {
        padding: 20px;
        border: 1px solid #fff;
        background: #fff;
        position: relative;

        .initial-wrapper {
          min-width: 35px;
          height: 35px;
          border-radius: 40px;
          background: var(--op-primary-color);
          display: flex;
          justify-content: center;
          align-items: center;

          .initial {
            font-size: 13px;
            font-family: Arial;
            color: #fff;
            text-align: center;
            flex: 1;
            margin: 0;
          }
        }
      }
    }

    .footer {
      .item {
        border: 1px solid #fff;
        background: #fff;
        padding: 10px 20px;
        cursor: pointer;

        &:hover {
          background: #e5e6e8;
        }

        &.disabled {
          opacity: 0.5;
          pointer-events: none;
        }
      }
    }
  }
}

.cursor-pointer {
  cursor: pointer !important;
}

.leaflet-touch .leaflet-control-attribution {
  display: none !important;
}

.ws-bubble {
  padding: 7px 14px 6px;
  background-color: rgb(255, 255, 255);
  border-radius: 0px 8px 8px;
  position: relative;
  transition: all 0.3s ease 0s;
  opacity: 1;
  transform-origin: center top 0px;
  z-index: 2;
  box-shadow: rgba(0, 0, 0, 0.13) 0px 1px 0.5px;
  margin-top: 4px;
  margin-left: 15px;
  margin-right: 15px;
  max-width: 100%;

  &::before {
    position: absolute;
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAmCAMAAADp2asXAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAACQUExURUxpccPDw9ra2m9vbwAAAAAAADExMf///wAAABoaGk9PT7q6uqurqwsLCycnJz4+PtDQ0JycnIyMjPf3915eXvz8/E9PT/39/RMTE4CAgAAAAJqamv////////r6+u/v7yUlJeXl5f///5ycnOXl5XNzc/Hx8f///xUVFf///+zs7P///+bm5gAAAM7Ozv///2fVensAAAAvdFJOUwCow1cBCCnqAhNAnY0WIDW2f2/hSeo99g1lBYT87vDXG8/6d8oL4sgM5szrkgl660OiZwAAAHRJREFUKM/ty7cSggAABNFVUQFzwizmjPz/39k4YuFWtm55bw7eHR6ny63+alnswT3/rIDzUSC7CrAziPYCJCsB+gbVkgDtVIDh+DsE9OTBpCtAbSBAZSEQNgWIygJ0RgJMDWYNAdYbAeKtAHODlkHIv997AkLqIVOXVU84AAAAAElFTkSuQmCC");
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: contain;
    content: "";
    top: 0px;
    left: -11px;
    width: 12px;
    height: 19px;
  }

  img {
    margin-bottom: 10px;
  }
}

.ws-bubble-header {
  width: 100%;
  height: 200px;
  background-color: rgb(224, 230, 237);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.ws-bubble-message {
  font-size: 14px;
  line-height: 19px;
  color: rgb(17, 17, 17);
  min-height: 30px;
  overflow-wrap: break-word; // Ensures text wraps if there is no space at the end of the container
  word-wrap: break-word; // For compatibility with older browsers
  white-space: pre-wrap;
}

.ws-bubble-time {
  text-align: right;
  margin-top: 4px;
  font-size: 12px;
  line-height: 16px;
  color: rgba(17, 17, 17, 0.5);
  margin-right: -8px;
  margin-bottom: -4px;
}

.op-ws-campaign-settings-modal-cancel-button {
  color: #000 !important;
}
