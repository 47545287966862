.kanban-deal {
  height: 100%;
  // padding: 10px 0;

  .kanban-view {
    height: 100%;
    display: grid;
    padding: 0 20px 20px 20px;
    overflow-x: auto;
    overflow-y: hidden;

    // &::-webkit-scrollbar {
    //   width: 5px !important;
    //   height: 0px !important;
    //   background: #3a35351c;
    // }

    // &::-webkit-scrollbar-thumb {
    //   background: #1b191550 !important;
    //   border-radius: 0px !important;
    // }

    .kanban-wrapper {
      height: 100%;
      min-width: 0px;
      display: flex;
    }

    .kanban-item {
      min-width: 300px;
      border: none !important;
      margin-left: 8px;
      background-color: rgb(243, 243, 243) !important;
      border-radius: 8px 8px 0 0;
      display: flex;
      flex-direction: column;
      flex-basis: 100%;

      &.kanban-hightlight {
        background-color: #ddd !important;
        .kanban-header {
          background-color: #ddd !important;

          .arrow-right path {
            fill: #ddd !important;
          }

          .arrow-left path {
            fill: #ddd !important;
          }
        }

        .kanban-body {
          background-color: #ddd !important;
        }
      }

      &:first-child {
        margin-left: 0px;
      }

      .kanban-header {
        background-color: rgb(243, 243, 243);
        box-shadow: 0 0 0 0 transparent;
        display: flex;
        align-items: flex-start;
        -webkit-box-pack: justify;
        justify-content: space-between;
        position: sticky;
        top: -1px;
        min-width: 45px;
        z-index: 1;
        height: 63px;
        cursor: pointer;

        .kanban-header-content {
          padding: 12px 8px 8px 15px;
          overflow: hidden;
          width: 100%;
        }

        .arrow-right {
          fill: rgb(243, 243, 243);
          position: absolute;
          right: -8px;
          top: 0px;
        }

        .arrow-left {
          fill: rgb(243, 243, 243);
          position: absolute;
          left: -1px;
          top: 0px;
        }
      }

      .kanban-body {
        height: calc(100vh - 189px);
        background-color: rgb(243, 243, 243);

        &::-webkit-scrollbar {
          width: 0px !important;
          height: 0px !important;
          background: #3a35351c;
        }

        &::-webkit-scrollbar-thumb {
          background: #1b191550 !important;
          border-radius: 0px !important;
        }
      }
    }
  }
}
