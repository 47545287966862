/* .audio-recorder-mic {
  height: 22px;
}

.audio-recorder {
  width: 45px;
}

/* Override the styles for recording */
/* .recording {
  width: 310px;
}

.audio-recorder-options[data-testid="ar_pause"],
.audio-recorder-options[data-testid="ar_cancel"] {
  display: none;
  visibility: hidden;
  margin: 0;
  padding: 0;
} */



.recorder-container {
  display: flex;
  align-items: center;
}

.recorder-mic {
  box-sizing: content-box;
  cursor: pointer;
  height: 16px;
  color: #000;
  padding: 12px;
}

.audio-recorder {
  background-color: transparent !important;
  box-shadow: none !important; 
  border-radius: 0;
  box-sizing: none !important;
  color: #000;
  width: 40px;
  display: flex;
  align-items: center;
  transition: all 0.2s ease-in;
  -webkit-tap-highlight-color: transparent;
}

.audio-recorder-mic {
  display: none;
  box-sizing: content-box;
  cursor: pointer;
  height: 16px;
  color: #000;
  padding: 12px;
}

.audio-recorder .audio-recorder-mic {
  border-radius: 20px;
}
.audio-recorder.recording .audio-recorder-mic {
  border-radius: 0;
}
.audio-recorder-timer,
.audio-recorder-status {
  color: #000;
  margin-left: 0px !important;
  font-family: poppins, sans-serif !important;
  font-size: 13px;
  font-weight: 400;
  line-height: 1;
}

.audio-recorder-status {
  margin-left: 15px;
  display: flex;
  align-items: baseline;
  flex-grow: 1;
  animation-name: fading-ar-status;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

.audio-recorder-status-dot {
  background-color: #d00;
  border-radius: 50%;
  height: 10px;
  width: 9px;
  margin-right: 5px;
}

.audio-recorder-options {
  display: none;
  box-sizing: content-box;
  height: 16px;
  cursor: pointer;
  padding: 12px;
}

.audio-recorder-options[data-testid="ar_cancel"] {
  display: none !important;
}

.audio-recorder-options ~ .audio-recorder-options {
  padding: 20px;
  border-radius: 0 5px 5px 0;
}

.recording {
  border-radius: 12px;
  width: 140px;
  transition: all 0.2s ease-out;
}

.display-none {
  display: none;
}

.audio-recorder-visualizer {
  margin-left: 10px !important;
  flex-grow: 1;
  align-self: center;
  display: flex;
  align-items: center;
  width: 100%;
}

.audio-recorder-visualizer canvas {
  width: 100%;
}

@keyframes fading-ar-status {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.button-hover-white:hover {
  background-color: #eee !important;
}