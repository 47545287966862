.project-content-container {
  display: flex;
}

.all-project-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.all-project-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.project-container {
  width: calc(33.33% - 50px);
  box-sizing: border-box;
  margin: 25px;
  border: none;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
}

.project-content {
  padding: 15px;
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
  text-align: center;
}

.project-image {
  width: 100%;
}

.project-title {
  font-size: 1.28571429em;
  font-weight: 700;
}

.project-location {
  color: rgba(0, 0, 0, 0.4);
  font-size: 1em;
}

.project-desc {
  color: rgba(0, 0, 0, 0.68);
  margin-top: 0.5em;
  font-size: 1em;
}

.view-phase {
  padding-top: 10px;
  border-top: 1px solid #ddd;
  display: flex;
  align-items: center;
  justify-content: center;
}

.view-phase-btn {
  border: 1px solid #19a738;
  color: white;
  background-color: #14be39;
  padding: 10px;
  border-radius: 5px;
  width: 150px;
  text-align: center;
  margin: auto;
  margin-bottom: 20px;
  font-size: 1em;
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.view-phase-btn:hover {
  background-color: #15a734;
}

.phase-count {
  padding: 5px 12px;
  border: 1px solid #3ba518;
  border-radius: 50px;
  background-color: #27971f;
  margin-left: 10px;
}

.toPhase {
  color: white;
  display: flex;
  align-items: center;
}

.project-img {
  height: 50vh;
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.project-img-set {
  height: 100%;
  width: 100%;
  object-fit: fill;
  opacity: 0.5;
}

.project-img-title {
  position: absolute;
  color: white;
  z-index: 1;
  text-align: center;
}

@media screen and (max-width: 750px) {
  .project-img {
    height: 25vh;
    background-color: black;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .project-container {
    width: auto;
    box-sizing: border-box;
    margin: 25px;
    border: none;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  }

  .project-content-container {
    display: block;
  }
}

/* Booking project/phase */

.bookingProject-box-display {
  display: flex;
}

.bookingProject-image-width {
  width: 70%;
}

.bookingProject-image {
  object-fit: cover;
  max-height: 400px;
  border-radius: 10px 0px 0px 10px;
}

.bookingProject-content-box {
  width: 50%;
  background-color: #fff;
  border-radius: 0px 10px 10px 0px;
}

.bookingProject-content-box-display {
  display: block;
  width: fit-content;
  margin: 0px 0px 0px 20px;
}

.carousel-control-next-icon {
  background-color: black;
  border-radius: 25px;
}

.carousel-control-prev-icon {
  background-color: black;
  border-radius: 25px;
}

.carousel-control-next {
  justify-content: right !important;
}

.carousel-control-prev {
  justify-content: left !important;
}

@media screen and (max-width: 1115px) {
  .bookingProject-box-display {
    display: block;
  }

  .bookingProject-image-width {
    width: 100%;
  }

  .bookingProject-image {
    object-fit: cover;
    max-height: 400px;
    border-radius: 10px 10px 0px 0px;
  }

  .bookingProject-content-box {
    width: 100%;
    background-color: #fff;
    border-radius: 0px 0px 10px 10px;
  }

  .bookingProject-content-box-display {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    margin: 0px 0px 20px;
  }

  .bookingProject-total-content {
    margin: 20px 0px 20px 0px;
  }

  .bookingProject-title {
    margin-top: 20px;
  }
}

.internal-project .op-swiper {
  .swiper {
    width: 100%;
    height: 100%;
  }

  .swiper-wrapper {
    position: absolute !important;
  }

  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .swiper {
    width: 100%;
    height: 300px;
    margin-left: auto;
    margin-right: auto;
  }

  .swiper-slide {
    background-size: cover;
    background-position: center;
  }

  .mySwiper2 {
    height: 380px;
    width: 100%;
  }

  .mySwiper {
    height: 60px;
    box-sizing: border-box;
    padding: 10px 0;
    border-radius: 5;
  }

  .mySwiper .swiper-slide {
    width: 25%;
    height: 100%;
    opacity: 0.4;
  }
}
