/* Deal title */
.title-content {
	background-color: #332e2e1c;
	border-radius: 5px;
	width: auto;
	height: auto;
	padding: 10px;
}

/* Deal Status */

.status {
	width: auto;
	height: auto;
}

.status-new {
	background-color: #ff7676;
	padding: 5px 7px;
	border-radius: 5px;
	color: white;
}

.status-active {
	background-color: #76d276;
	padding: 5px 7px;
	border-radius: 5px;
	color: white;
}

.status-default {
	background-color: #ecb35e;
	padding: 5px 7px;
	border-radius: 5px;
	color: white;
}

.score {
	padding: 1px 5px;
	background-color: #a333c8;
	border: 1px solid #a333c8;
	border-radius: 50%;
	color: white;
}

/* Display */
.prospect {
	font-weight: bold;
}

.vertically-center {
	display: flex;
	align-items: center;
}

::-webkit-scrollbar {
	width: 10px;
	height: 10px;
	background: #3a35351c;
}

::-webkit-scrollbar-thumb {
	background: #1b191550;
	border-radius: 20px;
}

.loading-indicator {
	font-size: 20px;
	font-weight: bold;
	color: #14120e81;
}

.hide {
	display: none;
}

.header-checkbox {
	border: 1px solid lightgray;
}

.date-checkbox {
	padding-left: 10px;
}

/* Deal record */
.deal-record {
	font-size: 16px;
	font-family: poppins, "sans-serif" !important;
	margin: 10px;
}

/* Pipeline filter */
.pipeline {
	width: 200px;
	padding: 8px;
	border: 1px solid #ccc;
	border-radius: 4px;
	font-size: 16px;
	transition: all 0.3s ease;
}

.options {
	font-size: 16px;
	background-color: #fff;
	color: #333;
	max-width: 200px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.pipeline:hover {
	border-color: #007bff;
	box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.all {
	width: auto;
	display: flex;
	justify-content: right;
}

/* Show/Hide button */

/* ---------------------------- */

.column-menu {
	position: absolute;
	top: 27%;
	background-color: #ffffff;
	border: 1px solid #ccc;
	padding: 10px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
	z-index: 1;
	max-height: 200px;
	overflow-y: auto;
	max-width: 100%;
	border-radius: 3px;
}

.column-menu-item {
	margin-top: 5px;
	margin-bottom: 5px;
	font-size: 14px;
}

.show-hide-div {
	margin-left: 20px;
}

.show-hide-button {
	padding: 9px 15px;
	border: 1px solid #97938b81;
	border-radius: 5px;
	background-color: white;
	cursor: pointer;
	font-size: 15px;
	box-shadow: none;
}

.show-hide-button:hover {
	transition: all 0.3s ease-in-out;
	border: 1px solid #007bff;
	box-shadow: 0 0 4px 0 #007bff;
}

// .divider {
// 	/* font-size: 30px; */
// 	display: flex;
// 	align-items: center;
// 	margin-bottom: 15px;
// }

// .divider::before,
// .divider::after {
// 	flex: 1;
// 	content: "";
// 	padding: 1px;
// 	background-color: #b9babb;
// 	margin: 5px;
// }

.input, select, textarea {
	font-family: poppins, "sans-serif" !important;
	font-size: 13px !important;
}
