.content-body-gl {
    height: calc(100vh - 60px);
    display: flex;
}

.section-sidebar-gl {
    width: 250px;
    height: 100%;
    overflow-y: auto;
    z-index: 1;
    flex-shrink: 0;
    background-color: #fff;
}