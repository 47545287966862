.ws-bubble {
  padding: 7px 14px 6px;
  background-color: rgb(255, 255, 255);
  border-radius: 0px 8px 8px;
  position: relative;
  transition: all 0.3s ease 0s;
  opacity: 1;
  transform-origin: center top 0px;
  z-index: 2;
  box-shadow: rgba(0, 0, 0, 0.13) 0px 1px 0.5px;
  margin-top: 4px;
  margin-left: 15px;
  margin-right: 15px;
  max-width: 100%;

  &::before {
    position: absolute;
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAmCAMAAADp2asXAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAACQUExURUxpccPDw9ra2m9vbwAAAAAAADExMf///wAAABoaGk9PT7q6uqurqwsLCycnJz4+PtDQ0JycnIyMjPf3915eXvz8/E9PT/39/RMTE4CAgAAAAJqamv////////r6+u/v7yUlJeXl5f///5ycnOXl5XNzc/Hx8f///xUVFf///+zs7P///+bm5gAAAM7Ozv///2fVensAAAAvdFJOUwCow1cBCCnqAhNAnY0WIDW2f2/hSeo99g1lBYT87vDXG8/6d8oL4sgM5szrkgl660OiZwAAAHRJREFUKM/ty7cSggAABNFVUQFzwizmjPz/39k4YuFWtm55bw7eHR6ny63+alnswT3/rIDzUSC7CrAziPYCJCsB+gbVkgDtVIDh+DsE9OTBpCtAbSBAZSEQNgWIygJ0RgJMDWYNAdYbAeKtAHODlkHIv997AkLqIVOXVU84AAAAAElFTkSuQmCC");
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: contain;
    content: "";
    top: 0px;
    left: -12px;
    width: 12px;
    height: 19px;
  }
}

.ws-bubble-header {
  width: 100%;
  height: 200px;
  background-color: rgb(224, 230, 237);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.ws-bubble-message {
  font-size: 14px;
  line-height: 19px;
  color: rgb(17, 17, 17);
  min-height: 30px;
  overflow-wrap: break-word; // Ensures text wraps if there is no space at the end of the container
  word-wrap: break-word; // For compatibility with older browsers
  white-space: pre-wrap;
}

.ws-bubble-time {
  text-align: right;
  margin-top: 4px;
  font-size: 12px;
  line-height: 16px;
  color: rgba(17, 17, 17, 0.5);
  margin-right: -8px;
  margin-bottom: -4px;
}

.op-ws-campaign-settings-modal-cancel-button {
  color: #000 !important;
}

.editable-div {
  span {
    font-size: 12px !important;
  }
}

/* Show placeholder when div is empty */
.editable-div:empty:before,
.editable-div:before {
  content: "Type your text here...";
  color: #999;
  position: absolute;
  pointer-events: none;
}

.editable-div.body-block:empty:before,
.editable-div.body-block:before {
  content: "Type your template body text here...";
}

.editable-div.header-block:empty:before,
.editable-div.header-block:before {
  content: "Type your template header text here...";
}

.editable-div.footer-block:empty:before,
.editable-div.footer-block:before {
  content: "Type your template footer text here...";
}

.editable-div.button-block:empty:before,
.editable-div.button-block:before {
  content: "Type your template button label here...";
}

.editable-div.button-phone-number:empty:before,
.editable-div.button-phone-number:before {
  content: "Phone Number";
}

.editable-div.button-url:empty:before,
.editable-div.button-url:before {
  content: "URL";
}

/* Hide placeholder when div contains any text */
.editable-div:not(:empty):before {
  content: "";
}

/* Remove placeholder if the div has only <br> or other non-visible elements */
.editable-div:empty::before {
  display: block;
}

.scrollable-modal {
  height: 70vh;
  overflow-y: auto;
}

.scrollable-modal::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Chrome, Safari and Opera */
}

.scrollable-modal {
  -ms-overflow-style: none; /* Hide scrollbar for Internet Explorer and Edge */
  scrollbar-width: none; /* Hide scrollbar for Firefox */
}

.epr_q53mwh.epr_vl50xg.epr_-3yva2a.epr_c90x4z.epr_-sisw4f.epr_mw4zr1.epr_-kg0voo.epr_iogimd.epr_wtwvf4.epr_-lzq3oa {
  display: none !important;
}

.op-scroll::-webkit-scrollbar {
  width: 5px;
}

.whastapp-campaign {
  .filepond--panel-root {
    background-color: transparent !important;
    border: none !important;
  }

  .filepond--drop-label {
    background-color: transparent !important;
    border: none !important;
  }


}
