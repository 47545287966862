.page-form {
  display: flex;
  width: 100%;
  height: calc(100vh - 56px);

  .page-start {
    width: 30%;
    height: 100%;
    background-color: #fff;
    padding: 20px;
    border-right: 1px solid #ddd;
  }

  .page-end {
    width: 70%;
    height: 100%;

    form {
      width: 100%;
      height: 100%;

      .card {
        background-color: transparent !important;
        border: none;

        .card-header {
          border-bottom: none;
          border-radius: 5px !important;
          background-color: #e5e6e8 !important;
          font-size: 13px;
          font-weight: bold;
          text-transform: uppercase;
          padding: 15px 20px;
        }

        .card-body {
          &.content {
            height: calc(100vh - 112px);
            overflow-y: auto;
          }
        }

        .card-footer {
          &.footer {
            height: 56px !important;
          }
        }

        .filepond--panel-root {
          background-color: #fff;
          border: 1px solid #dee2e6;
        }

        .preview {
          .filepond--action-remove-item {
            display: none !important;
          }
        }
      }
    }
  }
}
