.sidebar-menu {
  width: 56px !important;
  background-color: var(--op-primary-color) !important;
  opacity: 1 !important;
  z-index: 999999 !important;
}

.menu-layout {
  box-shadow: 0 0 0 transparent !important;
  height: 100% !important;
  width: 56px !important;
  z-index: 50 !important;
}

.menu-side {
  all: unset;
  display: flex !important;
  flex-direction: column !important;
  height: 100% !important;
  overflow-y: auto !important;
  width: 56px !important;
}

.menu-logo {
  all: unset !important;
  box-sizing: border-box !important;
  cursor: pointer !important;
  display: flex !important;
  flex: 0 0 56px !important;
  flex-direction: column !important;
  position: sticky !important;
  top: 0 !important;
  z-index: 53 !important;
}

.menu-item {
  all: unset !important;
  align-items: center !important;
  box-sizing: border-box !important;
  cursor: pointer !important;
  display: flex !important;
  flex: 0 0 56px !important;
  justify-content: center !important;
  position: relative !important;
}

.menu-logo-link {
  align-items: center !important;
  border-radius: 4px !important;
  display: flex !important;
  justify-content: center !important;
  left: 6px !important;
  padding: 7px !important;
  position: absolute !important;
  top: 6px !important;
}

.menu-logo-img {
  height: 30px;
  width: 30px;
}

.menu-link {
  align-items: center !important;
  border-radius: 4px !important;
  display: flex !important;
  height: 24px !important;
  justify-content: center !important;
  padding: 22px !important;
  width: 24px !important;
  color: #fff !important;

  &.active,
  &:hover {
    background-color: var(--op-secondary-color) !important;
    color: #fff !important;
  }
}

.menu-foot {
  position: sticky !important;
  bottom: 0 !important;
  all: unset !important;
  align-items: center !important;
  box-sizing: border-box !important;
  cursor: pointer !important;
  display: flex !important;
  flex: 0 0 56px !important;
  justify-content: center !important;

  .menu-modules {
    align-items: center !important;
    display: flex !important;
    height: 50px !important;
    justify-content: center !important;
    width: 100% !important;
    color: #fff !important;

    &.active {
      background-color: #fff !important;
      color: #000 !important;

      &:hover {
        background-color: #fff !important;
        color: #000 !important;
        width: 100% !important;
        height: 50px !important;
        border-radius: 0 !important;
      }
    }

    &:hover {
      background-color: var(--op-primary-color) !important;
      color: #fff !important;
      height: 24px !important;
      width: 24px !important;
      padding: 22px !important;
      border-radius: 4px !important;
    }
  }
}

.tooltip {
  margin-left: 5px !important;
}

.tooltip-arrow {
  margin-left: 10px;
  display: none !important;
}

.tooltip-inner {
  background-color: var(--op-primary-color) !important;
}


