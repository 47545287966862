.unit-details {
  height: 100%;
  display: flex;
  flex-direction: column;

  .section-header {
    width: 100%;
    background-color: #fff;
    border-bottom: 1px solid #ddd;
    height: 100px;
  }

  .section-body {
    height: 100%;
    display: flex;

    .section-sidebar {
      width: 20%;
      height: calc(100vh - 156px);
      overflow-y: auto;
      z-index: 1;
      flex-shrink: 0;
      background-color: #fff;
      border-right: 1px solid #ddd;
    }

    .section-content {
      height: 100%;
      width: 100%;
      height: calc(100vh - 156px);
      overflow: hidden auto;
    }
  }

  .swiperpd2 {
    height: 500px !important;
    width: 100%;

    .swiper-wrapper {
      position: absolute !important;
    }

    .swiper-slide {
      text-align: center;
      font-size: 18px;
      background: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .swiper-slide img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .swiper-slide .video {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      background-color: #000;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .swiper {
      width: 100%;
      height: 300px;
      margin-left: auto;
      margin-right: auto;
    }

    .swiper-slide {
      background-size: cover;
      background-position: center;
    }
  }

  .swiperpd3 {
    height: 400px !important;
    width: 100%;

    .swiper-wrapper {
      position: absolute !important;
    }

    .swiper-slide {
      text-align: center;
      font-size: 18px;
      background: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .swiper-slide img {
      display: block;
      width: 100%;
      height: auto;
      object-fit: cover;
    }

    .swiper-slide .video {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      background-color: #000;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .swiper {
      width: 100%;
      height: 300px;
      margin-left: auto;
      margin-right: auto;
    }

    .swiper-slide {
      background-size: cover;
      background-position: center;
    }
  }

  .swiperpd {
    height: 100px;
    box-sizing: border-box;
    border-radius: 5;

    .swiper-wrapper {
      position: absolute !important;
    }

    .swiper-slide {
      text-align: center;
      font-size: 18px;
      background: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }

    .swiper-slide img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 5px;
    }

    .swiper-slide .video {
      display: block;
      width: 100%;
      height: 100%;
      border-radius: 5px;
      object-fit: cover;
      background-color: #000;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
