.font {
    font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
}

.deal-count:hover {
    text-decoration: underline;
    cursor: pointer;
}

.apply-filter-btn {
    background-color: #3ab54a !important;
    color: white !important;
}

.whitespace-initial {
    white-space: initial;
}