.page-phase {
  .amenities-list {
    height: 400px !important;
    width: 40%;
    overflow-y: "auto";
    background-color: var(--op-primary-color);
    color: #fff;

    .header {
      display: flex;
      align-items: center;
      height: 50px;
      width: 100%;
      font-size: 15px;
      padding: 0 20px;
      border-bottom: 0.1px solid #ddd;
      text-transform: uppercase;
      font-weight: bold;
      letter-spacing: 1px;
    }

    .list-group-item {
      background-color: transparent !important;
      color: #fff !important;
      border: none !important;
    }

    .accordion {
      --bs-accordion-active-bg: var(--op-primary-color) !important;
      --bs-accordion-active-color: #fff !important;
      --bs-accordion-btn-bg: var(--op-primary-color) !important;
      --bs-accordion-color: #fff !important;
      --bs-accordion-bg: var(--op-primary-color) !important;
      --bs-accordion-btn-color: #fff !important;
      --bs-accordion-border-width: 0px !important;
      --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23ffffff%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e") !important;
      --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23ffffff%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e") !important;

      .accordion-button {
        font-size: 15px !important;
      }
    }
  }

  .swiperpd2 {
    height: 500px !important;
    width: 100%;

    .swiper-wrapper {
      position: absolute !important;
    }

    .swiper-slide {
      text-align: center;
      font-size: 18px;
      background: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .swiper-slide img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .swiper-slide .video {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      background-color: #000;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .swiper {
      width: 100%;
      height: 300px;
      margin-left: auto;
      margin-right: auto;
    }

    .swiper-slide {
      background-size: cover;
      background-position: center;
    }
  }

  .swiperpd {
    height: 100px;
    box-sizing: border-box;
    border-radius: 5;

    .swiper-wrapper {
      position: absolute !important;
    }

    .swiper-slide {
      text-align: center;
      font-size: 18px;
      background: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }

    .swiper-slide img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 5px;
    }

    .swiper-slide .video {
      display: block;
      width: 100%;
      height: 100%;
      border-radius: 5px;
      object-fit: cover;
      background-color: #000;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.swiperpd3 {
  height: 300px !important;
  width: 100%;

  .swiper-wrapper {
    position: absolute !important;
  }

  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .video {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      background-color: #000;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
