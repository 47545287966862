@font-face {
  font-family: "poppins";
  src: url("../fonts/poppins/Poppins-Regular.ttf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "poppinsExtraBold";
  src: url("../fonts/poppins/Poppins-ExtraBold.ttf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

:root {
  --op-primary-color: rgba(8, 14, 44, 1);
  --op-primary-hover-color: rgba(8, 14, 44, 0.1);
  --op-secondary-color: rgba(13, 110, 253, 1);
  --op-bg-color: rgba(8, 14, 44, 1);
  --bs-body-font-family: poppins, sans-serif !important;
  --bs-font-sans-serif: poppins, sans-serif !important;
}

body {
  margin: 0 !important;
  font-size: 12px !important;
  font-family: poppins, sans-serif !important;
  min-width: 1300px;
  overflow-x: hidden !important;
  overflow-y: hidden !important;
  background-color: #eee;
}

@media (max-width: 1300px) {
  body {
    overflow-x: auto !important;
  }
}

body {
  &.mobile {
    min-width: auto !important; // Remove min-width for specific URL
    @media (max-width: 1300px) {
      overflow-x: hidden !important;
    }
  }
}

.css-xtis2x-control {
  cursor: pointer !important;
}

.filepond--root {
  font-family: poppins, sans-serif !important;
}

.filepond--panel-root {
  background-color: #f5f5f6 !important;
  border: 1px solid #d7d7d9 !important;
}

.filepond--credits {
  display: none !important;
}

// ========================================= OP TYPHOGRAPHY ========================================= //

.op-text-bigger {
  font-size: 13px !important;
}

.op-text-medium {
  font-size: 12px !important;
}

.op-text-icon {
  font-size: medium !important;
}

.op-font {
  font-family: poppins, sans-serif !important;
}

.op-extrabold-font {
  font-family: poppinsExtraBold, sans-serif !important;
}

// ========================================= OP COLOR ========================================= //

.op-primary-color {
  background-color: var(--op-primary-color) !important;
}

.op-secondary-color {
  background-color: var(--op-secondary-color) !important;
}

.op-primary-bg {
  background-color: var(--op-bg-color) !important;
}

.op-secondary-bg {
  background-color: #eee;
}

.iziToast {
  font-family: poppins, sans-serif !important;
}

.iziToast-rtl {
  font-family: poppins, sans-serif !important;
}

.iziToast-overlay {
  z-index: 1000 !important;
}

// ========================================= OP BUTTON ========================================= //

.op-button {
  --bs-btn-padding-y: 0.2rem;
  --bs-btn-padding-x: 0.5rem;
  --bs-btn-font-size: 13px;
  font-size: 12px !important;
  font-family: poppins, "sans-serif" !important;
}

// ========================================= AVATAR ========================================= //

.avatar {
  display: flex;
  align-items: center;
  gap: 16px;
  text-decoration: none;
  color: #fff;

  & .avatar-img {
    width: 25px;
    height: 25px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: var(--op-primary-color);
  }

  & .avatar-txt {
    font-size: 13px;
    color: #fff;
    mix-blend-mode: difference;
  }

  & .avatar-img-tag {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

// ========================================= OP LABEL ========================================= //

.op-label {
  display: inline-block;
  line-height: 1;
  vertical-align: baseline;
  margin: 0 0.14285714em;
  background-color: #e8e8e8;
  background-image: none;
  padding: 0.5833em 0.833em;
  color: rgba(0, 0, 0, 0.6);
  text-transform: none;
  border: 0 solid transparent;
  border-radius: 0.28571429rem;
  font-weight: 500;
  font-size: 12px !important;

  &.badge {
    padding: 0.2em 0.5em !important;
    line-height: 1em !important;
    text-align: center !important;
    border: 0 solid transparent;
    border-radius: 0.28571429rem;
    font-size: 12px !important;
    font-weight: normal !important;
    color: #000;
  }

  &.circular {
    min-width: 2em;
    min-height: 2em;
    padding: 0.5em !important;
    line-height: 1em;
    text-align: center;
    border-radius: 500rem;

    &.sm {
      min-width: 1.5em;
      min-height: 1.5em;
      padding: 0.25em !important;
      line-height: 1;
      text-align: center;
      border-radius: 500rem;
      font-size: 10px !important;
      font-weight: bold !important;
    }
  }
}

$color_1: rgba(0, 0, 0, 0.85);
$color_2: #fff;
$border-color_1: transparent;
$border-top-color_1: rgba(34, 36, 38, 0.15);
$border-right-color_1: rgba(255, 255, 255, 0.15);
$border-bottom-color_1: rgba(255, 255, 255, 0.15);
$border-left-color_1: rgba(34, 36, 38, 0.15);

// ========================================= OP DIVIDER ========================================= //

.op.divider {
  margin: 1rem 0;
  line-height: 1;
  height: 0;
  letter-spacing: 0.05em;
  font-weight: 700;
  text-transform: uppercase;
  color: $color_1;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

.op.divider {
  &:not(.vertical) {
    &:not(.horizontal) {
      &:not(.horizontal-start) {
        border-top: 1px solid rgba(34, 36, 38, 0.15);
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      }
    }
  }
  > .icon {
    margin: 0;
    font-size: 12px;
    height: 1em;
    vertical-align: middle;
  }
  font-size: 12px;
}

.op.horizontal.divider {
  display: table;
  white-space: nowrap;
  height: auto;
  margin: "";
  line-height: 1;
  text-align: center;
  &:after {
    content: "";
    display: table-cell;
    position: relative;
    top: 50%;
    width: 50%;
    background-repeat: no-repeat;
    background-position: left 1em top 50%;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAABaAAAAACCAYAAACuTHuKAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyFpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNS1jMDE0IDc5LjE1MTQ4MSwgMjAxMy8wMy8xMy0xMjowOToxNSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIChXaW5kb3dzKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDo1OThBRDY4OUNDMTYxMUU0OUE3NUVGOEJDMzMzMjE2NyIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDo1OThBRDY4QUNDMTYxMUU0OUE3NUVGOEJDMzMzMjE2NyI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjU5OEFENjg3Q0MxNjExRTQ5QTc1RUY4QkMzMzMyMTY3IiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjU5OEFENjg4Q0MxNjExRTQ5QTc1RUY4QkMzMzMyMTY3Ii8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+VU513gAAADVJREFUeNrs0DENACAQBDBIWLGBJQby/mUcJn5sJXQmOQMAAAAAAJqt+2prAAAAAACg2xdgANk6BEVuJgyMAAAAAElFTkSuQmCC);
  }
  &:before {
    content: "";
    display: table-cell;
    position: relative;
    top: 50%;
    width: 50%;
    background-repeat: no-repeat;
    background-position: right 1em top 50%;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAABaAAAAACCAYAAACuTHuKAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyFpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNS1jMDE0IDc5LjE1MTQ4MSwgMjAxMy8wMy8xMy0xMjowOToxNSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIChXaW5kb3dzKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDo1OThBRDY4OUNDMTYxMUU0OUE3NUVGOEJDMzMzMjE2NyIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDo1OThBRDY4QUNDMTYxMUU0OUE3NUVGOEJDMzMzMjE2NyI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjU5OEFENjg3Q0MxNjExRTQ5QTc1RUY4QkMzMzMyMTY3IiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjU5OEFENjg4Q0MxNjExRTQ5QTc1RUY4QkMzMzMyMTY3Ii8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+VU513gAAADVJREFUeNrs0DENACAQBDBIWLGBJQby/mUcJn5sJXQmOQMAAAAAAJqt+2prAAAAAACg2xdgANk6BEVuJgyMAAAAAElFTkSuQmCC);
  }
}

.op.horizontal-start.divider {
  display: table;
  white-space: nowrap;
  height: auto;
  margin: "";
  line-height: 1;
  text-align: center;
  &:after {
    content: "";
    display: table-cell;
    position: relative;
    top: 50%;
    width: 100%;
    background-repeat: no-repeat;
    background-position: left 1em top 50%;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAABaAAAAACCAYAAACuTHuKAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyFpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNS1jMDE0IDc5LjE1MTQ4MSwgMjAxMy8wMy8xMy0xMjowOToxNSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIChXaW5kb3dzKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDo1OThBRDY4OUNDMTYxMUU0OUE3NUVGOEJDMzMzMjE2NyIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDo1OThBRDY4QUNDMTYxMUU0OUE3NUVGOEJDMzMzMjE2NyI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjU5OEFENjg3Q0MxNjExRTQ5QTc1RUY4QkMzMzMyMTY3IiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjU5OEFENjg4Q0MxNjExRTQ5QTc1RUY4QkMzMzMyMTY3Ii8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+VU513gAAADVJREFUeNrs0DENACAQBDBIWLGBJQby/mUcJn5sJXQmOQMAAAAAAJqt+2prAAAAAACg2xdgANk6BEVuJgyMAAAAAElFTkSuQmCC);
  }
}

.op.vertical.fluid.divider {
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  margin: 0;
  padding: 0;
  width: auto;
  height: 50%;
  line-height: 0;
  text-align: center;
  transform: translateX(-50%);
  &:after {
    position: absolute;
    left: 50%;
    content: "";
    z-index: 3;
    border-left: 1px solid rgba(34, 36, 38, 0.15);
    border-right: 1px solid rgba(255, 255, 255, 0.1);
    width: 0%;
    height: calc(100%);
    top: auto;
    bottom: 0;
  }
  &:before {
    position: absolute;
    left: 50%;
    content: "";
    z-index: 3;
    border-left: 1px solid rgba(34, 36, 38, 0.15);
    border-right: 1px solid rgba(255, 255, 255, 0.1);
    width: 0%;
    height: calc(100%);
    top: -100%;
  }
}

.op.vertical.divider {
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  margin: 0;
  padding: 0;
  width: auto;
  height: 50%;
  line-height: 0;
  text-align: center;
  transform: translateX(-50%);
  &:after {
    position: absolute;
    left: 50%;
    content: "";
    z-index: 3;
    border-left: 1px solid rgba(34, 36, 38, 0.15);
    border-right: 1px solid rgba(255, 255, 255, 0.1);
    width: 0%;
    height: calc(100% - 1rem);
    top: auto;
    bottom: 0;
  }
  &:before {
    position: absolute;
    left: 50%;
    content: "";
    z-index: 3;
    border-left: 1px solid rgba(34, 36, 38, 0.15);
    border-right: 1px solid rgba(255, 255, 255, 0.1);
    width: 0%;
    height: calc(100% - 1rem);
    top: -100%;
  }
}

.op.hidden.divider {
  border-color: $border-color_1 !important;
  &:after {
    display: none;
  }
  &:before {
    display: none;
  }
}

.op.divider.inverted {
  color: $color_2;
  border-top-color: $border-top-color_1 !important;
  border-left-color: $border-left-color_1 !important;
  border-bottom-color: $border-bottom-color_1 !important;
  border-right-color: $border-right-color_1 !important;
  &:after {
    border-top-color: $border-top-color_1 !important;
    border-left-color: $border-left-color_1 !important;
    border-bottom-color: $border-bottom-color_1 !important;
    border-right-color: $border-right-color_1 !important;
  }
  &:before {
    border-top-color: $border-top-color_1 !important;
    border-left-color: $border-left-color_1 !important;
    border-bottom-color: $border-bottom-color_1 !important;
    border-right-color: $border-right-color_1 !important;
  }
}

.op.horizontal.inverted.divider {
  color: $color_2;
}

.op.vertical.inverted.divider {
  color: $color_2;
}

.op.fitted.divider {
  margin: 0;
}

.op.clearing.divider {
  clear: both;
}

.op.section.divider {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

@media only screen and (max-width: 767px) {
  .op.grid {
    .stackable.row {
      .op.vertical.divider {
        display: table;
        white-space: nowrap;
        height: auto;
        margin: "";
        overflow: hidden;
        line-height: 1;
        text-align: center;
        position: static;
        top: 0;
        left: 0;
        transform: none;
        &:after {
          position: static;
          left: 0;
          border-left: none;
          border-right: none;
          content: "";
          display: table-cell;
          position: relative;
          top: 50%;
          width: 50%;
          background-repeat: no-repeat;
          background-position: left 1em top 50%;
        }
        &:before {
          position: static;
          left: 0;
          border-left: none;
          border-right: none;
          content: "";
          display: table-cell;
          position: relative;
          top: 50%;
          width: 50%;
          background-repeat: no-repeat;
          background-position: right 1em top 50%;
        }
      }
    }
  }
  .op.stackable.grid {
    .op.vertical.divider {
      display: table;
      white-space: nowrap;
      height: auto;
      margin: "";
      overflow: hidden;
      line-height: 1;
      text-align: center;
      position: static;
      top: 0;
      left: 0;
      transform: none;
      &:after {
        position: static;
        left: 0;
        border-left: none;
        border-right: none;
        content: "";
        display: table-cell;
        position: relative;
        top: 50%;
        width: 50%;
        background-repeat: no-repeat;
        background-position: left 1em top 50%;
      }
      &:before {
        position: static;
        left: 0;
        border-left: none;
        border-right: none;
        content: "";
        display: table-cell;
        position: relative;
        top: 50%;
        width: 50%;
        background-repeat: no-repeat;
        background-position: right 1em top 50%;
      }
    }
  }
}

// ========================================= OP BREADCRUMBS ================================== //

.op-breadcrumbs {
  display: flex;
  flex-direction: row-reverse;
  flex-shrink: 0;
  width: fit-content;
  margin-bottom: 15px;
  position: relative;
  border-radius: 4px;

  .op-breadcrumbs-item {
    transition: all 0.2s ease-in-out;
    height: 30px;
    background: white;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
    line-height: 30px;
    padding-left: 30px;
    padding-right: 10px;
    font-size: 12px;
    color: #000;
    position: relative;
    cursor: pointer;
    float: left;

    &::after {
      transition: all ease-in-out 0.2s;
      content: "";
      position: absolute;
      left: calc(100% - 10px);
      top: 5px;
      z-index: 1;
      width: 0;
      height: 0;
      border: 11px solid #fff;
      border-left-color: transparent;
      border-bottom-color: transparent;
      box-shadow:
        0px 0px 0 0px #d9d9d9,
        5px -5px 10px -4px #d9d9d9;
      transform: rotate(45deg);
      margin-left: -1px;
    }

    &:last-child {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      padding-left: 10px;
    }

    &:first-child {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      background-color: #eee;

      &::after {
        content: "";
        display: none;
      }

      &:hover {
        background-color: rgba(23, 165, 98, 0.15);
      }
    }

    &:hover {
      background-color: #f9f9f9;

      &::after {
        border: 11px solid #f9f9f9;
        border-left-color: transparent;
        border-bottom-color: transparent;
      }
    }
  }
}

// ========================================= OP GRID ========================================= //

.ag-op {
  --ag-border-color: transparent !important;
  --ag-background-color: transparent !important;
  --ag-row-border-width: 0.5px !important;
  --ag-row-border-color: #eee !important;
  --ag-selected-row-background-color: color-mix(in srgb, #eee, #eee 8%) !important;
  --ag-row-hover-color: color-mix(in srgb, #eee, #eee 12%) !important;
  --ag-font-family: poppins, "sans-serif" !important;

  .ag-header {
    background-color: #fff !important;
    color: #fff !important;
    border-radius: 8px !important;
    margin-bottom: 10px;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
    font-size: 12px !important;
    font-weight: bold !important;
    .ag-header-cell {
      font-size: 12px !important;
      font-weight: bold !important;
      &.center {
        .ag-header-cell-label {
          justify-content: center !important;
        }
      }
      &.right {
        .ag-header-cell-label {
          justify-content: flex-end !important;
        }
      }
    }
  }

  &.ag-op-secondary {
    .ag-header {
      background-color: #eee !important;
      border-radius: 0px !important;
    }
  }

  .ag-center-cols-viewport {
    background-color: #fff !important;
  }

  .ag-body-viewport {
    background-color: #fff !important;
  }

  .ag-ltr .ag-cell-focus:not(.ag-cell-range-selected):focus-within,
  .ag-ltr .ag-context-menu-open .ag-cell-focus:not(.ag-cell-range-selected),
  .ag-ltr .ag-full-width-row.ag-row-focus:focus .ag-cell-wrapper.ag-row-group,
  .ag-ltr .ag-cell-range-single-cell,
  .ag-ltr .ag-cell-range-single-cell.ag-cell-range-handle,
  .ag-rtl .ag-cell-focus:not(.ag-cell-range-selected):focus-within,
  .ag-rtl .ag-context-menu-open .ag-cell-focus:not(.ag-cell-range-selected),
  .ag-rtl .ag-full-width-row.ag-row-focus:focus .ag-cell-wrapper.ag-row-group,
  .ag-rtl .ag-cell-range-single-cell,
  .ag-rtl .ag-cell-range-single-cell.ag-cell-range-handle {
    border: none !important;
  }

  .ag-cell {
    display: flex;
    align-items: center;
    font-size: 12px !important;
    line-height: 1.2;

    &.ag-cell-wrap-text {
      white-space: normal;
      word-break: break-word;
    }

    &.ag-cell-no-padding {
      padding: 0 !important;
    }

    &.right {
      justify-content: flex-end;
      text-align: right;
    }

    &.center {
      justify-content: center;
      text-align: center;

      .ag-cell-wrapper {
        justify-content: center;
      }
    }
  }

  .ag-cell-wrapper {
    display: flex;
    align-items: center;
    height: auto;
    width: 100%;
  }

  .ag-cell-value {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .ag-row-auto-height {
    .ag-cell {
      height: auto;
      padding: 5px;
    }
  }

  .ag-header-cell-resize {
    &::after {
      width: 1px !important;
      background-color: #eee !important;
    }
  }

  .ag-hide-header {
    .ag-header {
      display: none !important;
    }
  }

  .ag-picker-field,
  .ag-paging-row-summary-panel,
  .ag-paging-page-summary-panel {
    font-size: 12px !important;
  }

  .ag-checkbox-input-wrapper {
    &:focus {
      box-shadow: 0px !important;
    }
    &.ag-checked {
      &::after {
        color: #000 !important;
        border: none !important;
      }
    }
  }

  .ag-paging-panel {
    background-color: #fff !important;
    border-radius: 8px !important;
    margin-top: 5px;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  }

  .ag-body-viewport {
    border-radius: 8px !important;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
    .ag-row {
      background-color: #fff;
      border: none !important;
      z-index: 0 !important;
    }
  }

  .ag-body-vertical-scroll {
    width: 0 !important;
    max-width: 0 !important;
    min-width: 0 !important;
  }
}

.op-ag-action {
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0;
  font-size: 12px;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;

  .op-ag-action-item {
    display: block;
    padding: 0.25rem 1rem;
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    text-decoration: none;
    white-space: nowrap;
    border: 0;

    &:hover {
      cursor: pointer;
      background: rgb(240, 240, 240);
    }
  }
}

.offcanvas-title {
  font-size: 15px !important;
  font-weight: 600 !important;
}

.offcanvas-header {
  border-bottom: 1px solid #dee2e6;
  background-color: #f8f9fa;
}

.offcanvas-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  background-color: #f8f9fa;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  border-top: 1px solid #dee2e6;
}

.offcanvas-body {
  height: calc(100vh - 120px);
  overflow-y: auto;
}

.op-error-message {
  color: var(--bs-form-invalid-color);
  font-size: 0.875em;
  margin-top: 0.25rem;
  width: 100%;
}

.form-control.is-invalid {
  box-shadow: 0 0 0 0.25rem var(--op-primary-hover-color) !important;
}

.react-datepicker-wrapper {
  width: 100%;
}

.tox .tox-editor-header {
  z-index: 1 !important;
}

.rdp-caption_label {
  font-size: 15px;
  font-family: poppins, "sans-serif" !important;
  padding: 0 !important;
  svg {
    display: none !important;
  }
}

.css-b62m3t-container {
  border-radius: 0.375rem !important;
}

.op-modal-bigger {
  max-width: 80% !important;
  width: 80% !important;
  margin: 2rem 11rem !important;
}

.op-modal-dialog-fullscreen {
  left: 56px !important;
  width: calc(100% - 56px) !important;
}

.timeline-note {
  .vertical-timeline-element-content {
    background-color: #fff6d6 !important;
  }
}

.timeline-activity {
  .vertical-timeline-element-content {
    background-color: #eff6ff !important;
  }
}

.vertical-timeline-element {
  margin: 2em 0 !important;
}

.vertical-timeline-element-content {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.vertical-timeline-element:first-child {
  margin-top: 0 !important;
}

.vertical-timeline-element-content p {
  font-size: 12px !important;
}

.btn-circle.btn-sm {
  width: 30px;
  height: 30px;
  padding: 6px 0px;
  border-radius: 15px;
  font-size: 8px;
  text-align: center;
}

.btn-circle.btn-md {
  width: 50px;
  height: 50px;
  padding: 7px 10px;
  border-radius: 25px;
  font-size: 10px;
  text-align: center;
}

.btn-circle.btn-xl {
  width: 70px;
  height: 70px;
  padding: 10px 16px;
  border-radius: 35px;
  font-size: 12px;
  text-align: center;
}

.sidebar-tab {
  border-right: 1px solid #ddd;
  .nav-item {
    .nav-link {
      color: #000 !important;

      &.active {
        background-color: var(--op-primary-color) !important;
        color: #fff !important;
      }

      &:hover {
        background-color: var(--op-primary-hover-color);
      }

      .badge {
        --bs-badge-padding-x: 5px !important;
        --bs-badge-padding-y: 3px !important;
        --bs-badge-font-size: 10px !important;
        --bs-badge-font-weight: normal !important;
        --bs-badge-color: #fff !important;
        --bs-badge-border-radius: 4px !important;
      }
    }
  }
}

.form-select {
  &.dark {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='none' stroke='%23adb5bd' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3E%3C/svg%3E") !important;
  }
}

.op-input-no-focus {
  border: 1px solid #dee2e6 !important;
  box-shadow: none !important;
  outline: none !important;

  &.no-border-left {
    border-left: 0px !important;
  }

  &.no-border-right {
    border-right: 0px !important;
  }

  &:focus {
    border: 1px solid #dee2e6 !important;
    box-shadow: none !important;
    outline: none !important;

    &.no-border-left {
      border-left: 0px !important;
    }

    &.no-border-right {
      border-right: 0px !important;
    }
  }
}

.op-scrollbar {
  overflow: auto !important;
  &::-webkit-scrollbar {
    width: 2px !important;
  }
}

.bg-orange {
  --bs-bg-opacity: 1;
  background-color: #fd7e14 !important;
}

.btn-orange {
  background-color: #fd7e14 !important;
  color: #fff !important;
}

.form-date {
  input[type="date"]::-webkit-inner-spin-button,
  input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
  }
}

.op-note-editor {
  .quill {
    display: flex;
    flex-direction: column-reverse;
    border: none !important;
  }

  .ql-container {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    background: #fefcfc;
    border: none !important;
  }

  .ql-snow.ql-toolbar {
    display: block;
    background-color: #fff6d6;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border: none !important;
  }

  .ql-editor {
    min-height: 10em;
    background-color: #fff6d6 !important;
    border: none !important;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
}

.form-check {
  input {
    &.is-invalid {
      box-shadow: 0 0 0 0.25rem var(--op-primary-hover-color) !important;
    }
  }
}

// $input-placeholder-color: #999 !important;
$input-font-size: 12px;
$input-font-size-sm: 12px;
$input-font-size-lg: 12px;
$input-focus-border-color: var(--op-primary-color);
$input-focus-box-shadow: 0 0 0 1px var(--op-primary-color);
$form-invalid-border-color: var(--op-primary-color);
$form-label-font-size: 12px;
$form-label-margin-bottom: 5px;
$form-check-input-width: 1.5em;
$form-check-input-focus-border: var(--op-primary-color);
$form-check-input-focus-box-shadow: var(--op-primary-color);
$form-check-input-checked-bg-color: var(--op-primary-color);
$form-switch-width: 2.5em;
$btn-close-focus-shadow: none;
$btn-font-size: 12px;
$dropdown-font-size: 12px;
$dropdown-link-hover-color: #fff;
$dropdown-link-hover-bg: var(--op-primary-color);
$dropdown-link-active-color: #fff;
$dropdown-link-active-bg: var(--op-primary-color);
$dropdown-item-padding-y: 0.5rem;
$nav-link-color: #000;
$nav-link-hover-color: #000;
$nav-tabs-link-active-color: #fff;
$nav-tabs-link-active-bg: var(--op-primary-color);
$list-group-active-color: #fff;
$list-group-active-bg: var(--op-primary-color);
$tooltip-font-size: 12px;
$breadcrumb-divider: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='8' height='8'><path d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='#999'/></svg>");

@import "~bootstrap/scss/bootstrap.scss";
