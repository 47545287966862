.calendar {
    height: 400px !important;
}

.calendar-all-option {
    width: 200px;
    font-size: 12px;
    transition: all 0.3s ease;
    border-radius: 5px;
    z-index: 2;
}

.calendarOption {
    display: flex;
    justify-content: right;
}

.all-select-option {
    padding: 0px 20px 10px 0px;
}

.datAllTitleOptions {
    padding: 2px 15px;
    max-height: 100%;
    max-width: 100%;
    height: 37px;
    width: 65px;
    text-align: center;
    border: 1px solid black;
    background-color: #6d0000;
    color: white;
    cursor: pointer;
    font-size: 20px;
}

.datTitleOptions {
    padding: 7px 20px;
    max-height: 100%;
    max-width: 100%;
    height: 37px;
    width: 65px;
    text-align: center;
    border: 1px solid black;
    background-color: #6d0000;
    color: white;
    cursor: pointer;
}

.datTitleOptions:hover, .datAllTitleOptions:hover {
    background-color: black;
    transition: 0.5s;
}

.datTitleOptions.selected,
.datAllTitleOptions.selected {
    background-color: white;
    color: #797979;
}

.dat-select-option {
    display: flex;
}

.fc-prev-button, .fc-next-button, .fc-today-button {
    background-color: #f5f5f5 !important;
    color: black !important;
    border: 1px solid !important;
    text-shadow: 0 1px 1px rgba(255, 255, 255, .75);
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .2), 0 1px 2px rgba(0, 0, 0, .05);
    border-color: rgba(0, 0, 0, .1) rgba(0, 0, 0, .1) rgba(0, 0, 0, .25) !important;
    background-image: linear-gradient(to bottom, #fff, #e6e6e6);
    background-repeat: repeat-x;
    height: 2.1em;
    border-radius: 0px !important;
    padding: 0em 0.6em !important;
    cursor: pointer;
}

.fc-prev-button:hover, .fc-next-button:hover {
    background-image: linear-gradient(to bottom, #b8b0b080, #b8b0b080);
}

.fc-dayGridMonth-button, .fc-timeGridWeek-button, .fc-timeGridDay-button {
    color: black !important;
    border: 1px solid !important;
    text-shadow: 0 1px 1px rgba(255, 255, 255, .75);
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .2), 0 1px 2px rgba(0, 0, 0, .05);
    border-color: rgba(0, 0, 0, .1) rgba(0, 0, 0, .1) rgba(0, 0, 0, .25) !important;
    background-image: linear-gradient(to bottom, #fff, #e6e6e6);
    height: 2.1em;
    border-radius: 0px !important;
    padding: 0em 0.6em !important;
    cursor: pointer;
}

.fc-button-active {
    background-image: linear-gradient(to bottom, #ffffffb7, #e6e6e6f3);
}


.fc-icon-chevron-left, .fc-icon-chevron-right {
    vertical-align: top !important;
}

.fc-toolbar-title {
    font-size: 35px !important;
    font-weight: 500;
}

.fc-daygrid-day-number, .fc-col-header-cell-cushion {
    color: black;
    text-decoration: none;
}

.fc-more-popover {
    width: 300px;
}

.popoverStyle {
    z-index: 9999;
}

.calendar-loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    border-radius: 8px;
    /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
}

.calendar-loader:before {
    content: '';
    border: 8px solid #f3f3f3;
    border-top: 8px solid #3498db;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.calendar-fs.loading {
    pointer-events: none;
    filter: blur(1px);
}