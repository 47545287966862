.lead-report-title {
    text-align: center;
    font-weight: 400;
    font-size: 22px;
    font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
}

.text-family{
    font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
    font-size: 14px;
}

.ag-header-cell.custom-header-center .ag-header-cell-text {
    justify-content: center;
}

.header-spacing {
    white-space: normal;
}

.days-header {
    display: flex;
    justify-content: center;
}