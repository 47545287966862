.op-search-container {
  position: relative;

  .op-search-wrapper {
    position: relative;
    width: 20rem;
    font-size: 13px;

    .op-search {
      padding: 0;
      position: relative;
      background-color: transparent;
      z-index: 999;

      .op-icon-search {
        position: absolute;
        color: grey;
        top: 50%;
        left: 0.9rem;
        transform: translateY(-48%);
      }

      .op-icon-close {
        position: absolute;
        color: var(--op-primary-color);
        top: 50%;
        right: 0.9rem;
        transform: translateY(-48%);
        cursor: pointer;
      }

      input {
        padding-left: 2.5rem;
        padding-right: 2.5rem;
        font-size: 13px;
        line-height: 1.7;
        border-radius: 50rem;
        box-shadow: none;
        z-index: 999;
      }
    }
  }

  .op-search-backdrop {
    background-color: rgba(33, 35, 44, 0.48);
    position: fixed;
    bottom: 0;
    top: 0;
    right: 0;
    left: 0;
    z-index: 99;
  }

  .op-search-dropdown {
    position: absolute;
    width: 20rem;
    height: 30rem;
    font-size: 15px;
    border-radius: 5px;
    z-index: 999;
    display: flex;
    margin-top: 10px;
    overflow: hidden;
    transform: translateX(0);
    transition:
      width 0.4s,
      transform 0.4s;

    &.active {
      transform: translateX(-180px);
      width: 40rem;

      .right-panel {
        width: 70%;
      }
    }

    .left-panel {
      background-color: var(--op-bg-color);
      width: 0;
      height: 100%;
      padding: 10px 0;

      &.open {
        width: 30%;
      }

      &.close {
        width: 0;
      }

      .search-filter {
        display: flex;
        align-items: center;
        position: relative;
        color: #fff;

        // &:hover {
        //   background-color: var(--op-primary-color) !important;
        // }

        // &.active {
        //   background-color: var(--op-primary-color) !important;
        // }

        .search-filter-text {
          padding-top: 2px;
        }

        .nav-icon {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 30px;
          color: #fff;
        }

        .nav-icon-wrapper {
          width: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    .right-panel {
      background-color: #fff;
      width: 100%;
      height: 100%;

      h6 {
        margin: 20px 20px;
      }

      .list-item {
        background-color: transparent;
        display: flex;
        align-items: center;
        border: none;

        &:hover {
          background-color: #eee !important;
        }

        .list-icon {
          width: 50px;
          display: flex;
          align-items: center;
          justify-content: center;

          .list-icon-wrapper {
            width: 40px;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 10px;
          }
        }

        .list-content {
          .list-title {
            font-weight: bold;
          }

          .list-desc {
            font-size: 12px;
          }
        }
      }
    }
  }
}

.op-root-header {
  background: var(--pd-color-surface-foreground);
  box-shadow:
    0 0 1px 1px rgb(0 0 0 / 5%),
    0 1px 2px -2px rgb(0 0 0 / 6%),
    0 1px 3px 0 rgb(0 0 0 / 7%);
  display: flex;
  height: 56px;
  padding: 0 12px 0 12px;
  width: 100%;
  z-index: 40;

  .op-root-header__left {
    justify-content: flex-start;
    align-items: center;
    display: flex;
    flex: 1;
  }

  .op-root-header__center {
    align-items: center;
    display: flex;
    flex: 1;
    gap: 8px;
    justify-content: center;
  }

  .op-root-header__right {
    align-items: center;
    display: flex;
    flex: 1;
    justify-content: flex-end;

    .op-root-header__item {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 35px;
    }

    .op-root-header__profile {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 10px;

      .avatar {
        display: flex;
        align-items: center;
        gap: 16px;
        text-decoration: none;
        color: #fff;
        cursor: pointer;

        & .avatar-img {
          width: 25px;
          height: 25px;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          background-color: var(--op-primary-color);
        }

        & .avatar-img2 {
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
        }

        & .avatar-txt {
          font-size: 13px;
          color: #fff;
          mix-blend-mode: difference;
        }

        & .avatar-img-tag {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      .op-root-header__profile_text {
        max-width: 80px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .op-root-header__notification {
    position: absolute;
    top: 45px;
    right: 215px;
    z-index: 40;
    width: 600px;
    height: 500px;
    z-index: 1000000;

    .op-root-header__notification-left {
      width: 200px;
      background-color: var(--op-primary-color);
      border-top-left-radius: 5;
      border-bottom-left-radius: 5;
    }

    .op-root-header__notification-right {
      width: 400px;
      background-color: #fff;

      .op-root-header__notification-right__header {
        display: flex;
        align-items: center;
        padding: 15px 10px;
        background-color: #fff;
      }

      .op-root-header__notification-right__body {
        background-color: #fff;
        height: 420px;
        overflow: auto;

        .op-root-header__notification-right__body-item {
          cursor: pointer;
          display: flex;
          width: 100%;
          padding: 15px 10px;
          clear: both;
          font-weight: 400;
          color: var(--bs-dropdown-link-color);
          text-align: inherit;
          text-decoration: none;
          white-space: nowrap;
          border: 0;
        }
      }

      .op-root-header__notification-right__footer {
        background-color: #eeeeee;
        height: 50px;
        width: 400px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
    }
  }

  .op-root-header__notification-backdrop {
    background-color: rgba(33, 35, 44, 0.48);
    position: fixed;
    bottom: 0;
    top: 0;
    right: 0;
    left: 0;
    z-index: 999999;
  }
}

.profile-menu {
  &.dropdown-menu {
    top: 40px !important;
    right: 0 !important;
    width: 270px !important;
  }
}
