.booking-view {
  height: 100%;
  display: flex;
  flex-direction: column;



  .accordion {
    --bs-accordion-active-bg: transparent !important;
    --bs-accordion-btn-focus-box-shadow: 0 0 0 0 rgba(13, 110, 253, 0) !important;
    --bs-accordion-border-radius: 0 !important;
    --bs-accordion-border-width: 0 !important;
    --bs-accordion-body-padding-x: 1rem !important;
    --bs-accordion-body-padding-y: 1rem !important;
    .accordion-item {
      border-bottom: 1px solid #dee2e6 !important;
    }

    .sidebar-accordion {
      padding: 10px;

      .accordion-button {
        font-size: 13px !important;
        font-weight: bold;
        text-align: center;
        display: flex;
        align-items: center;
        padding: 5px 10px;
        border-radius: 4px;
        width: auto !important;

        &:hover {
          background-color: #eee;
        }

        &::after {
          width: 13px !important;
          height: 13px !important;
          background-size: 13px !important;
          order: -1 !important;
          margin-left: 0 !important;
          margin-right: 0.5em !important;
          display: none;
        }
      }
    }
  }

  .section-header {
    width: 100%;
    background-color: #fff;
    border-bottom: 1px solid #ddd;
    height: 150px;
  }

  .section-content {
    height: calc(100vh - 206px);
    display: flex;

    .page-start {
      width: 17%;
      height: 100%;
      overflow-y: auto;
      flex-shrink: 0;
      background-color: #fff;
      border-right: 1px solid #ddd;
    }

    .page-end {
      width: 100%;
      height: 100%;
      overflow: hidden auto;
      .card-header.panel {
        border-bottom: none;
        border-radius: 5px !important;
        background-color: #f1f1f1 !important;
        font-size: 13px;
        font-weight: bold;
        text-transform: uppercase;
        padding: 15px 20px;
      }
    }
  }

  .file-display {
    .filepond--action-remove-item {
      display: none !important;
    }
  }

}
