.customer-kit {
  background-color: #f3f3f3;
  height: 100vh;

  .saleskit-swiper {
    padding: 30px 10px !important;

    .saleskit-swiper-child-wrapper {
      padding: 1.4rem;
    }

    .swiperpd2 {
      height: 500px;
      width: 100%;

      .swiper-slide {
        text-align: center;
        font-size: 18px;
        background: transparent;
        display: flex;
        justify-content: center;
        align-items: center;
        background-size: cover;
        background-position: center;

        img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        .video {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
          background-color: #000;
          color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    .saleskit-swiper-child {
      width: 100%;
      padding: 0 3rem;
      position: absolute;
      bottom: -40px;
      z-index: 1;

      .swiperpd {
        height: 100%;
        width: 100%;
        box-sizing: border-box;
        border-radius: 5;

        .swiper-slide {
          text-align: center;
          font-size: 18px;
          background: #fff;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;

          img {
            display: block;
            width: 100%;
            height: 80px;
            object-fit: cover;
            border-radius: 5px;
          }

          .video {
            display: block;
            width: 100%;
            height: 100%;
            border-radius: 5px;
            object-fit: cover;
            background-color: #000;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
  }

  @media screen and (max-width: 480px) {
    .op-swiper {
      .mySwiper2 {
        width: 100%;
        height: 200px;
        .swiper-slide img {
          border-top-left-radius: 0.375rem;
          border-bottom-left-radius: 0.375rem;
          border-top-right-radius: 0.375rem;
        }
      }

      .mySwiper {
        height: 60px;
        .swiper-slide {
          width: 100%;
          opacity: 0.9;
        }
      }
    }

    .saleskit-swiper {
      --bs-gutter-x: 0 !important;
      --bs-gutter-y: 0 !important;
      margin: 0 !important;
      padding: 0 !important;

      .saleskit-swiper-child-wrapper {
        padding: 1rem !important; 
      }

      .swiperpd2 {
        height: 350px !important;
      }

      .saleskit-swiper-child {
        padding: 0 0.5rem !important;
        bottom: -50px !important;
        .swiperpd {
          height: 60px;

          .swiper-slide {
            img {
              height: 50px;
            }
          }
        }
      }
    }
  }

  .desktop-logo {
    display: block;
  }

  .mobile-logo {
    display: none;
  }

  .w-100 li {
    list-style-type: none;
    position: relative;
    padding-left: 30px;
  }

  .w-100 li::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 12px;
    height: 12px;
    background-color: transparent;
    border-radius: 50%;
    border: 3px solid #bbb;
  }

  .w-100 li::before {
    width: 15px;
    height: 15px;
  }

  @media screen and (max-width: 480px) {
    .desktop-logo {
      display: none !important;
    }

    .mobile-logo {
      display: block !important;
    }

    // section {
    //   padding-bottom: 100px !important;
    // }

    .customer-kit-unit-details {
      display: block;
    }

    .customer-kit-navigation {
      display: flex;
      flex-direction: column;
    }

    .sales-kit-unit-width {
      width: 100%;
    }
  }

  .button-flex {
    display: flex;
    flex-wrap: wrap;

    button {
      margin-top: 10px;
    }
  }

  .amenities-list {
    height: 400px !important;
    overflow-y: "auto";
    background-color: var(--op-primary-color);
    color: #fff;

    .header {
      display: flex;
      align-items: center;
      height: 50px;
      width: 100%;
      font-size: 15px;
      padding: 0 20px;
      border-bottom: 0.1px solid #ddd;
      text-transform: uppercase;
      font-weight: bold;
      letter-spacing: 1px;
    }

    .list-group-item {
      background-color: transparent !important;
      color: #fff !important;
      border: none !important;
    }

    .accordion {
      --bs-accordion-active-bg: var(--op-primary-color) !important;
      --bs-accordion-active-color: #fff !important;
      --bs-accordion-btn-bg: var(--op-primary-color) !important;
      --bs-accordion-color: #fff !important;
      --bs-accordion-bg: var(--op-primary-color) !important;
      --bs-accordion-btn-color: #fff !important;
      --bs-accordion-border-width: 0px !important;
      --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23ffffff%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e") !important;
      --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23ffffff%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e") !important;

      .accordion-button {
        font-size: 15px !important;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .button-flex {
    display: flex;
    flex-wrap: wrap;

    button {
      width: 100%;
    }
  }
}

.fadeOut {
  opacity: 0;
  transition: opacity 0.5s ease;
  /* Adjust the duration as needed */
}

.cutomerKit-box-display {
  display: flex;
}

.customerKit-image-width {
  width: 60%;
}

.customerKit-image {
  object-fit: cover;
  max-height: 450px;
  border-radius: 10px 0px 0px 10px;
}

.customerKit-content-box {
  width: 50%;
  background-color: #fff;
  border-radius: 0px 10px 10px 0px;
}

.customerKit-content-box-display {
  display: block;
  width: fit-content;
  margin: 0px 0px 0px 20px;
}

@media screen and (max-width: 957px) {
  .cutomerKit-box-display {
    display: block;
  }

  .customerKit-image-width {
    width: 100%;
  }

  .customerKit-image {
    object-fit: cover;
    max-height: 450px;
    border-radius: 10px 10px 0px 0px;
  }

  .customerKit-content-box {
    width: 100%;
    background-color: #fff;
    border-radius: 0px 0px 10px 10px;
  }

  .customerKit-content-box-display {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    margin: 0px 0px 20px;
  }

  .customerKit-total-content {
    margin: 20px 0px 20px 0px;
  }

  .customerKit-title {
    margin-top: 20px;
  }
}

@media screen and (max-width: 530px) {
  .customerKit-content-box-display {
    display: block;
    width: fit-content;
    justify-content: space-evenly;
  }
}

.customer-kit-register {
  width: 100% !important;
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.customer-kit-unit-details {
  display: flex;
}

// unit details

.sales-kit-unit-width {
  width: 85%;
}
