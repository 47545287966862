@font-face {
  font-family: "poppins";
  src: url("../fonts/poppins/Poppins-Regular.ttf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "poppinsExtraBold";
  src: url("../fonts/poppins/Poppins-ExtraBold.ttf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

.error-page {
  background: #fff;
  font-family: poppins, sans-serif !important;
}

